<template>
  <div class="Login">
    <form  @submit.prevent.stop="login" class="Wrapper">
      <router-link to="/">
          <!-- <img class="logo-img" src="@/assets/logo_branco.svg" alt="" srcset=""> -->
      </router-link>
      <br/>
      <h1>Iniciar sessão</h1>
      <!-- <p v-if="error.hasError">{{error.message}}</p> -->
      <input type="text" placeholder="Nome do utilizador" v-model="form.userName"  />
      <input type="password" placeholder="Senha" v-model="form.password" />
      <router-link class="router-link" to="/recover"> <span>Esqueceu a palavra passe?</span></router-link>
      <button class="btn-sumit" ><span class="Next">Seguinte</span></button>
      <!-- <a type="submit" class="btn-sumit" ><span class="Next">Seguinte</span></a> -->
    </form>
     <modal
        v-if="confirmModal"
        @close="confirmModal = false"
      />
  </div>
</template>
<script>
import Modal from '@/components/shared/modal'
export default {
  name: 'LoginPage',
  data () {
    return {
      confirmModal: false,
      form: {
        userName: '',
        grant_type: '',
        password: ''
      },
      error: {
        message: '',
        hasError: false
      }
    }
  },
  components: {
    'modal': Modal
  },
  created () {
    document.title = 'Ucall | Login'
  },
  methods: {
    login () {
      var querystring = require('querystring')
      var self = this
      this.$store.dispatch('LOGIN', querystring.stringify({
        userName: this.form.userName,
        grant_type: 'password',
        password: this.form.password
      }))
        .then(sucess => {
          if (sucess === false) {
            this.confirmModal = true
            self.error.message = 'Login inválido'
          }
        })
        .catch((e) => {
          this.confirmModal = true
          self.error.hasError = true
          if (e.response) {
            self.error.message = e.response.data.message
          } else {
            self.error.message = 'Não foi possível estabelecer conexão, tente mais tarde!'
          }
          // this.error.message = 'Não foi possível estabelecer conexão, tente mais tarde!'
          // this.error.message = e.message
        }).finally(() => {
          setTimeout(() => {
            this.error.hasError = false
          }, 3000)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.Login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: #bb3e41;
  .router-link{
    &:hover{
      text-decoration: underline!important;
    }
    span{
      color: #fff!important;
      opacity: .7!important;
      &:hover{
        opacity: 1!important;
        text-decoration: underline!important;
      }
    }
  }
  @media(max-width:560px){
    .logo-img{
      display: none;
    }
    .create-count{
      margin-top: 25px!important;
    }
  }
  a{
    text-decoration: none!important;
  }
  .Wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media(max-width:768px){
      width: 70%;
    }
     @media(max-width:560px){
         align-items: center;
    }
    h1 {
      color: #ffffff;
      padding: 20px;
      font-family: "NexaLight", sans-serif;
      font-size: 25px;
      font-weight: lighter;
      opacity: 1;
      letter-spacing: 0;
    }
    input::placeholder, .router-link {
      color: #888;
    }
    input {
      background: #fff;
      padding: 20px 40px;
      border-radius: 6px;
      border: none;
      margin: 10px 0;
      width: 450px;
      color: #333;
      font-size: 18px;
      font-family: "NexaLight", sans-serif;
      @media(max-width:560px){
        padding: 15px 20px!important;
        width: 100%!important;
      }
    }
    p {
      color: #ffffff;
      padding: 20px;
      font-family: "NexaLight", sans-serif;
      font-size: 18px;
      text-align: right;
      opacity: 0.5;
      cursor: pointer;
    }
    .btn-sumit{
      @media(max-width: 768px){
        width: 100%!important;
      }
    }
    button.btn-sumit,
    a.btn-sumit {
      background: #db9e1a;
      border-radius: 6px;
      padding: 0px 40px;
      border: none;
      margin: 10px 0;
      width: 450px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      @media(max-width:560px){
        width: 100%!important;
        padding: 0px 20px;
        height: 50px;
      }
      &:hover{
        background: #f8ac09;
      }
      font-family: "NexaLight", sans-serif;
    }
    span {
      color: #fdb00b;
      padding: 20px;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      opacity: 0.9;
      cursor: pointer;
    }
    .Next {
      font-size: 18px;
      color: #fff;
      opacity: 0.9;
      letter-spacing: 0;
      font-family: "NexaLight", sans-serif;
    }
  }
  .toobar-container{
    width: 100%;
    height: auto;
    padding: 0 10%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    @media(max-width:560px){
      padding: 0!important;
    }
  }
}
</style>
<style>
.logo{
  display: none!important
}
</style>
