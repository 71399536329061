<template>
  <div class="courses-modal">
    <transition name="modal">
      <div class="modal-container">
        <div class="modal-wrapper">
          <button class="modal-btn-close" @click="$emit('close')">X</button>
          <h3 class="modal-title">{{ModalTitle}}</h3>
          <p class="p-text">Tente novamente.</p>
          <button v-if="ShowOklBtn"  @click="$emit('close')" class="btn-yes-ok">Ok</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data () {
    return {
      ShowOklBtn: true,
      ShowYeslBtn: true
    }
  },
  props: {
    ModalTitle: {
      type: String,
      default: () => 'Login Inválido!'
    },
    ModalBtnContinue: {
      type: Boolean,
      default: () => false
    },
    ModalGoHome: {
      type: Boolean,
      default: () => false
    },
    ShowCancelBtn: {
      type: Boolean,
      default: () => true
    },
    ShowCancelYes: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    Confirm () {
      this.ModalTitle = 'Foi submetido o pedido de informação, será contactado em breve pela equipa Rise Work.'
      this.ShowCancelBtn = false
      this.ShowOklBtn = true
      this.ShowYeslBtn = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .p-text{
    font-size: 17px;
    padding: 10px;
  }
  .modal-title{
    font-size: 20px;
  }
  .courses-modal{
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .modal-container {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #34204122 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    transition: opacity 0.3s ease;
    @media(max-width:768px){
      backdrop-filter: blur(11px);
      -webkit-backdrop-filter: blur(11px);
    }
  }
  .moadal-title{
    max-width: 250px;
  }
  .cancel{
    background-color: #fff!important;
    border: #551948 1px solid!important;
    color: #551948!important;
    margin-top: 20px!important;
  }
  .cancel,
  .btn-yes-ok{
    border: none;
    padding: 10px;
    width: 150px;
    margin-top: 30px;
    background-color: #bb3e41;
    cursor: pointer;
    border-radius: 6px;
    color: #fff;
    &:hover{
      background-color: #bb3e4188;
    }
  }
  .modal-wrapper {
    max-width:1131px ;
    width: 350px;
    max-height: 704px;
    height: 350px;
    background: #f2f2f2dc;
    display: flex;
    box-shadow: 6px 6px 15px #00000015;
    border-radius: 22px;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    @media(max-width:768px){
      margin-top: 10vh;
      width: 80%;
      height: 80vh;
    }
    .modal-btn-close{
      position: absolute;
      top: 15px;
      right: 15px;
      width: 43px;
      height: 43px;
      font-weight: bold;
      font-size: 16px;
      z-index: 777;
      -webkit-box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      border: none;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
      @media(max-width:768px){
        top: -60px;
        right:0px;
      }
    }
  }
</style>
